<template>

  <section id="get-in-touch">
    <div class="container">
      <div class="row">
        <div class="col-lg-7">
          <div class="cancel-sub">
            <div class="cancel-sub__title">
              <div class="title__row title__red">{{ getInTouch.cancelYourSubscription }}</div>
              <div class="title__row">{{ getInTouch.inAOneClick }}</div>
              <div class="title_row">{{ getInTouch.ourOnlineCancel }}</div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <p class="cancel-sub__p">{{ getInTouch.text1 }}</p>
                <p class="cancel-sub__p">{{ getInTouch.text2 }}</p>
              </div>
              <div class="col-md-6">
                <div class="desc__block">{{ getInTouch.desc1 }}</div>
                <div class="desc__block">{{ getInTouch.desc2 }}</div>
                <div class="desc__block">{{ getInTouch.desc3 }}</div>
                <div class="desc__block">{{ getInTouch.desc4 }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5">
          <div class="form">
            <form id="form-get-in-touch" @submit.prevent="submitForm">
              <div class="form__title">{{ getInTouch.title }}</div>
              <div class="form__row">
                <input
                  v-validate="'required|min:3'"
                  :class="{'form__inp--required': errors.has('firstName') }"
                  v-model="firstName"
                  name="firstName"
                  class="form__inp"
                  id="first_name"
                  :placeholder="getInTouch.firstName"/>
              </div>
              <div class="form__row">
                <input
                  v-validate="'required|min:3'"
                  :class="{'form__inp--required': errors.has('lastName') }"
                  v-model="lastName"
                  name="lastName"
                  class="form__inp"
                  id="last_name"
                  :placeholder="getInTouch.lastName"/>
              </div>
              <div class="form__row">
                <input
                  v-validate="'required|email'"
                  :class="{'form__inp--required': errors.has('email') }"
                  v-model="email"
                  name="email"
                  class="form__inp"
                  id="email"
                  :placeholder="getInTouch.email"/>
              </div>
              <div class="form__row">
                <the-mask
                  v-validate="'required|min:12'"
                  :class="{'form__inp--required': errors.has('phone') }"
                  v-model="phone"
                  mask="+### (##) ### ## ##"
                  name="phone"
                  class="form__inp"
                  id="phone"
                  :placeholder="getInTouch.phone"/>
              </div>
              <div class="form__row">
                <textarea
                  v-validate="'required|min:3'"
                  :class="{'form__inp--required': errors.has('message') }"
                  v-model="message"
                  name="message"
                  class="form__textarea"
                  id="message"
                  :placeholder="getInTouch.msg">
                </textarea>
              </div>
              <button class="form__btn">{{ getInTouch.send }}</button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <Loader v-if="showLoader"/>
    <Modal v-if="showModal"/>
  </section>

</template>

<script>
import {mapState, mapMutations} from 'vuex';
import Modal from '../Modal/Index';
import Loader from "../Loader/Loader";
import {TheMask} from 'vue-the-mask'

export default {
  name: "GetInTouch",
  components: {
    Modal,
    Loader,
    TheMask
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      message: '',
      phone: ''
    }
  },
  computed: {
    ...mapState([
      'vocab', 'locale', 'routes', 'showModal', 'showLoader',
    ]),
    getInTouch() {
      return this.vocab[this.locale].getInTouch;
    },
  },
  methods: {
    submitForm() {
      this.$validator.validate().then(result => {
        if (result) {
          this.showLoaderHandler();
          this.axios({
            method: "post",
            url: 'https://msubserv.com/api/mail/',
            data: {...this.$data, host: 'lovershelter.com'},
          })
            .then(res => {
              this.hideLoaderHandler();
              if (res.data.status === 'ok') {
                this.setModalText(this.vocab[this.locale].mailSent);
                console.log(res);
                this.clearForm();
              }
            })
            .catch(err => {
              this.hideLoaderHandler();
              console.log(err);
              this.setModalText(err);
            })
        } else {
          return;
        }
      });
    },

    clearForm() {
      this.firstName = "";
      this.lastName = "";
      this.email = "";
      this.message = "";
    },
    ...mapMutations([
      'setModalText', 'errorEmailInput', 'clearErrorStatus', 'hideLoaderHandler', 'showLoaderHandler'
    ])
  }
}
</script>

<style scoped>

</style>
